import { defineStore } from 'pinia'

import useActions from './actions'
import { type StateCoursesType } from './types'

export const useCoursesStore = defineStore('courses', () => {
  const state = reactive<StateCoursesType>({
    courseDetail: {
      data: null,
      isLoading: false
    },
    courseReview: {
      data: null,
      isLoading: true
    },
    courseContent: {
      data: null,
      isLoading: false
    },
    wishlist: {
      data: [],
      isLoading: false
    },
    courseList: {
      data: null,
      meta: null,
      isLoading: false
    },
    searchResult: {
      data: null,
      meta: null,
      isLoading: false
    }
  })

  const actions = useActions(state)
  return {
    state,
    ...actions
  }
})
